import { lazy } from 'react';

import { Code as CodeIcon } from '@goshippo/components';

import { Navigate, Route, Routes } from 'react-router-dom';

import { NavigationMenuCategory } from '~/src/app/components/Layout/components/NavigationMenu';
import { NavigationMenuIcon } from '~/src/components/NavigationMenuIcon';

const APIConfig = lazy(() => import('./pages/APIConfig'));
const Webhooks = lazy(() => import('./pages/Webhooks'));

export const APIConfigRoutes = () => (
  <Routes>
    <Route element={<APIConfig />} path="api" />
    <Route element={<Webhooks />} path="webhooks" />
    <Route element={<Navigate to="api" />} path="*" />
  </Routes>
);

export const apiConfigNavigationCategory: NavigationMenuCategory = {
  icon: <NavigationMenuIcon icon={CodeIcon} />,
  items: [
    {
      name: 'apiConfig.items.webhooks',
      route: '/api-config/webhooks',
    },
    {
      name: 'apiConfig.items.devKeys',
      route: '/api-config/api',
    },
  ],
  name: 'apiConfig.title',
};
