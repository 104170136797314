import { Typography, TypographyProps } from '@goshippo/components';

type Props = {
  title: string;
} & Omit<TypographyProps, 'component'>;

export const PageHeader = ({ title }: Props) => (
  <Typography component="h1" fontWeight="bold" variant="h1">
    {title}
  </Typography>
);
