import { styled } from '@goshippo/components';
import { NavItem, Navigation } from '@goshippo/libraries/Navigation';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { navigationMenuContainer } from '~/src/app/shared/layoutStyles';
import { FeatureFlags, useFeatureFlag } from '~/src/app/utils/featureflag';
import { useAuthentication } from '~/src/hooks/useAuthentication';
import { useUserEntitlements } from '~/src/hooks/useUserEntitlements';
import { apiConfigNavigationCategory } from '~/src/views/APIConfiguration/APIConfigurationRoutes';
import { createAccountNavigationCategory } from '~/src/views/Account/AccountRoutes';
import { activityNavigationCategory } from '~/src/views/Activity/ActivityRoutes';
import { analyticsNavigationCategory } from '~/src/views/Analytics/AnalyticsRoutes';
import { createSettingsNavigationCategory } from '~/src/views/FulfillmentNetwork/FulfillmentNetworkRoutes';
import { createFulfillmentReportsNavigationCategory } from '~/src/views/FulfillmentReports/FulfillmentReportsRoutes';
import { reconciliationNavigationCategory } from '~/src/views/Reconciliation/ReconciliationRoutes';
import { simulateNavigationCategory } from '~/src/views/Simulate/SimulateRoutes';

const Container = styled('div')`
  ${navigationMenuContainer}
`;

export type NavigationMenuCategory = {
  beta?: boolean;
  icon?: JSX.Element;
  items: NavItem[];
  name: string;
};

const getTranslatedMenuCategory = (
  t: TFunction,
  menuCategory: NavigationMenuCategory,
): NavigationMenuCategory => {
  const translateItem = (item: NavItem) => ({ ...item, name: t(item.name) });

  return {
    icon: menuCategory.icon,
    items: menuCategory.items.map(translateItem),
    name: t(menuCategory.name),
    ...(menuCategory.beta ? { beta: menuCategory.beta } : {}),
  };
};

export const NavigationMenu = () => {
  const {
    entitlements: { hasCustomBranding, isStaff, numUserSeats },
  } = useUserEntitlements();

  const { isImpersonating } = useAuthentication();
  const simulationFeatureFlag = useFeatureFlag(FeatureFlags.SIMULATION);
  const simulationEnabled = simulationFeatureFlag?.enabled || false;

  const betaReportsFeatureFlag = useFeatureFlag(FeatureFlags.BETA_REPORTS);
  const betaReportsEnabled = betaReportsFeatureFlag?.enabled || false;

  const surchargesReportsFeatureFlag = useFeatureFlag(FeatureFlags.SURCHARGES_REPORTS);
  const surchargesReportsEnabled = surchargesReportsFeatureFlag?.enabled || false;

  const analyticsReportsFeatureFlag = useFeatureFlag(FeatureFlags.ANALYTICS_REPORTS);
  const analyticsReportsEnabled = analyticsReportsFeatureFlag?.enabled || false;

  // show fulfillment reports nav category
  const showFulfillmentReports =
    isStaff || isImpersonating || betaReportsEnabled || surchargesReportsEnabled;

  // show analytics reports nav category
  const showAnalyticsReports = isStaff || isImpersonating || analyticsReportsEnabled;

  const { t } = useTranslation('', { keyPrefix: 'navigationMenu' });
  const { pathname } = useLocation();

  return (
    <Container
      sx={{
        '[id^=nav-cat]': {
          fontWeight: 'bold',
        },
      }}
    >
      <Navigation
        categories={[
          getTranslatedMenuCategory(t, activityNavigationCategory),
          getTranslatedMenuCategory(t, createSettingsNavigationCategory(hasCustomBranding)),
          getTranslatedMenuCategory(t, apiConfigNavigationCategory),
          ...(showAnalyticsReports
            ? [getTranslatedMenuCategory(t, analyticsNavigationCategory)]
            : showFulfillmentReports
            ? [
                getTranslatedMenuCategory(
                  t,
                  createFulfillmentReportsNavigationCategory(
                    isStaff,
                    isImpersonating,
                    betaReportsEnabled,
                    surchargesReportsEnabled,
                  ),
                ),
              ]
            : []),
          getTranslatedMenuCategory(t, createAccountNavigationCategory(numUserSeats)),
          ...(isStaff || simulationEnabled
            ? [getTranslatedMenuCategory(t, simulateNavigationCategory)]
            : []),
          ...(isStaff ? [getTranslatedMenuCategory(t, reconciliationNavigationCategory)] : []),
        ]}
        currentRoute={pathname}
        linkComponent={Link}
      />
    </Container>
  );
};
