import { Box, Stack } from '@goshippo/components';

import { useTranslation } from 'react-i18next';

import { PageHeader } from '~/src/components/PageHeader';

export const Status = () => {
  const { t } = useTranslation('overview');

  return (
    <Stack>
      <Box>
        <PageHeader title={t('status')} />
      </Box>
      <iframe
        height={'1000'}
        src="https://status.goshippo.com/"
        style={{ border: 'none', outline: 'none' }}
        title="API Status Page"
        width={'100%'}
      />
    </Stack>
  );
};
