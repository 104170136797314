import { lazy } from 'react';

import { MonitorHeartOutlined } from '@goshippo/components';

import { Navigate, Route, Routes } from 'react-router-dom';

import { NavigationMenuCategory } from '~/src/app/components/Layout/components/NavigationMenu';
import { FeatureFlags, useFeatureFlag } from '~/src/app/utils/featureflag';
import { NavigationMenuIcon } from '~/src/components/NavigationMenuIcon';
import { useAuthentication } from '~/src/hooks/useAuthentication';
import { useUserEntitlements } from '~/src/hooks/useUserEntitlements';

const Overview = lazy(() => import('./pages/Overview'));
const Surcharges = lazy(() => import('./pages/Surcharges'));

export const FulfillmentReportsRoutes = () => {
  const {
    entitlements: { isStaff },
  } = useUserEntitlements();

  const { isImpersonating } = useAuthentication();

  const betaReportsFeatureFlag = useFeatureFlag(FeatureFlags.BETA_REPORTS);
  const betaReportsEnabled = betaReportsFeatureFlag?.enabled || false;

  const surchargesReportsFeatureFlag = useFeatureFlag(FeatureFlags.SURCHARGES_REPORTS);
  const surchargesReportsEnabled = surchargesReportsFeatureFlag?.enabled || false;

  // show fulfillment reports nav category
  const showFulfillmentReports =
    isStaff || isImpersonating || betaReportsEnabled || surchargesReportsEnabled;

  const fallBackNavigate = surchargesReportsEnabled ? 'surcharges' : 'overview';

  if (!showFulfillmentReports) return <Navigate to="/" />;

  return (
    <Routes>
      {(isStaff || isImpersonating || betaReportsEnabled) && (
        <Route element={<Overview />} path="overview" />
      )}
      {(isStaff || isImpersonating || surchargesReportsEnabled) && (
        <Route element={<Surcharges />} path="surcharges" />
      )}
      <Route element={<Navigate to={fallBackNavigate} />} path="*" />
    </Routes>
  );
};

export const createFulfillmentReportsNavigationCategory = (
  isStaff: boolean,
  isImpersonating: boolean,
  betaReportsEnabled: boolean,
  surchargesReportsEnabled: boolean,
): NavigationMenuCategory => ({
  beta: true,
  icon: <NavigationMenuIcon icon={MonitorHeartOutlined} />,
  items: [
    ...(isStaff || isImpersonating || betaReportsEnabled
      ? [
          {
            name: 'fulfillmentReports.items.overview',
            route: '/fulfillment-reports/overview',
          },
        ]
      : []),
    ...(isStaff || isImpersonating || surchargesReportsEnabled
      ? [
          {
            name: 'fulfillmentReports.items.surcharges',
            route: '/fulfillment-reports/surcharges',
          },
        ]
      : []),
  ],
  name: 'fulfillmentReports.title',
});
