import { SvgIconComponent, colors } from '@goshippo/components';

type Props = {
  icon: SvgIconComponent;
};

export const NavigationMenuIcon = ({ icon: Icon }: Props) => (
  <Icon
    sx={{
      fill: colors.secondary,
    }}
  />
);
