import { useAuthentication } from '~/src/hooks/useAuthentication';
import { salesSimulationFullPage } from '~/src/views/Simulate/pages/Simulate/constants/links';

import { DefaultScreenSkeleton } from './DefaultScreenSkeleton';
import { FullWidthLayoutSkeleton } from './FullWidthLayoutSkeleton';
import { LayoutSkeleton } from './LayoutSkeleton';

type Props = {
  children: React.ReactElement;
};

const RedirectingSkeleton = () => {
  if (location.pathname.includes(salesSimulationFullPage)) return <FullWidthLayoutSkeleton />;
  return (
    <LayoutSkeleton>
      <DefaultScreenSkeleton />
    </LayoutSkeleton>
  );
};

export const AuthGuard = ({ children }: Props) => {
  const { isAuthenticated, loginWithRedirect } = useAuthentication();

  if (!isAuthenticated) {
    loginWithRedirect({ returnTo: `${location.pathname}${location.search}` });

    return <RedirectingSkeleton />;
  }

  return children;
};
