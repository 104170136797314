import { lazy } from 'react';

import { Insights as InsightsIcon } from '@goshippo/components';

import { Navigate, Route, Routes } from 'react-router-dom';

import { NavigationMenuCategory } from '~/src/app/components/Layout/components/NavigationMenu';
import { FeatureFlags } from '~/src/app/utils/featureflag/featureFlags';
import { useFeatureFlag } from '~/src/app/utils/featureflag/useFeatureFlag';
import { NavigationMenuIcon } from '~/src/components/NavigationMenuIcon';
import { useUserEntitlements } from '~/src/hooks/useUserEntitlements';

const Simulate = lazy(() => import('./pages/Simulate'));

export const SimulateRoutes = () => {
  const {
    entitlements: { isStaff },
  } = useUserEntitlements();

  const simulationFeatureFlag = useFeatureFlag(FeatureFlags.SIMULATION);
  const simulationEnabled = simulationFeatureFlag?.enabled || false;

  if (!isStaff || !simulationEnabled) return <Navigate to="/" />;

  return (
    <Routes>
      <Route element={<Simulate />} path="simulate" />
      <Route element={<Navigate to="simulate" />} path="*" />
    </Routes>
  );
};

export const simulateNavigationCategory: NavigationMenuCategory = {
  icon: <NavigationMenuIcon icon={InsightsIcon} />,
  items: [
    {
      name: 'simulate.items.simulate',
      route: '/simulate/simulate',
    },
  ],
  name: 'simulate.title',
};
