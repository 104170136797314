import { styled } from '@goshippo/components';

import { useLocation } from 'react-router-dom';

import { useNewRelicIdentifyEvent } from '~/src/hooks/useNewRelicIdentifyEvent';
import { usePortalAppcues } from '~/src/hooks/usePortalAppcues';
import { usePortalFullStory } from '~/src/hooks/usePortalFullStory';
import { analyticsSurchargesReports } from '~/src/views/Analytics/constants/links';
import {
  sigmaOperationalReports,
  sigmaSurchargesReports,
} from '~/src/views/FulfillmentReports/constants/links';
import { salesSimulationFullPage } from '~/src/views/Simulate/pages/Simulate/constants/links';

import { aside, container, content, main, sigmaStyles } from '../../shared/layoutStyles';
import { FullScreenBannerWrapper } from './components/FullScreenBannerWrapper';
import { MobileMenuHeader } from './components/MobileMenuHeader';
import { NavigationBar } from './components/NavigationBar/NavigationBar';
import { NavigationMenu } from './components/NavigationMenu';

const Container = styled('div')`
  ${container}
`;

const Aside = styled('aside')`
  ${aside}
`;

const Content = styled('div')`
  ${content}
`;

type MainProps = {
  pathname: string;
};

const Main = styled('main')<MainProps>`
  ${main}
  ${({ pathname }) =>
    pathname.includes(sigmaOperationalReports) ||
    pathname.includes(sigmaSurchargesReports) ||
    (pathname.includes(analyticsSurchargesReports) && sigmaStyles)}
`;

export const Layout: React.FC = ({ children }) => {
  const location = useLocation();
  usePortalFullStory();
  useNewRelicIdentifyEvent();
  usePortalAppcues();

  if (
    location.pathname.includes('/account/invoices/details/') ||
    location.pathname.includes('/resend_verification') ||
    location.pathname.includes(salesSimulationFullPage)
  ) {
    return <>{children}</>;
  }

  return (
    <>
      <FullScreenBannerWrapper />
      <NavigationBar />

      <Container>
        <Aside>
          <NavigationMenu />
        </Aside>

        <Content>
          <MobileMenuHeader />
          <Main pathname={location.pathname}>{children}</Main>
        </Content>
      </Container>
    </>
  );
};
