import { lazy } from 'react';

import { PersonOutline as PersonIcon } from '@goshippo/components';

import { Navigate, Route, Routes } from 'react-router-dom';

import { NavigationMenuCategory } from '~/src/app/components/Layout/components/NavigationMenu';
import { NavigationMenuIcon } from '~/src/components/NavigationMenuIcon';
import { useUserEntitlements } from '~/src/hooks/useUserEntitlements';

const Team = lazy(() => import('./pages/Team'));
const Billing = lazy(() => import('./pages/Billing'));
const Plan = lazy(() => import('./pages/Plan'));
const Invoices = lazy(() => import('./pages/Invoices'));
const Profile = lazy(() => import('./pages/Profile'));
const InvoiceDisplay = lazy(() =>
  import('./pages/Invoices/components/InvoiceDisplay/InvoiceDisplay').then(
    ({ InvoiceDisplay }) => ({ default: InvoiceDisplay }),
  ),
);

export const AccountRoutes = () => {
  const {
    entitlements: { numUserSeats },
  } = useUserEntitlements();

  // user management is only available if number of allowed seats > 1
  const showTeamPage = numUserSeats > 1;

  return (
    <Routes>
      {showTeamPage && <Route element={<Team />} path="team" />}
      <Route element={<Billing />} path="billing" />
      <Route element={<Plan />} path="plan" />
      <Route element={<Invoices />} path="invoices" />
      <Route element={<InvoiceDisplay />} path="invoices/details/:id" />
      <Route element={<Profile />} path="profile" />
      <Route element={<Navigate to="billing" />} path="*" />
    </Routes>
  );
};

export const createAccountNavigationCategory = (numUserSeats: number): NavigationMenuCategory => ({
  icon: <NavigationMenuIcon icon={PersonIcon} />,
  items: [
    {
      name: 'account.items.billing',
      route: '/account/billing',
    },
    {
      name: 'account.items.invoices',
      route: '/account/invoices',
    },
    {
      name: 'account.items.plan',
      route: '/account/plan',
    },
    {
      name: 'account.items.profile',
      route: '/account/profile',
    },
    ...(numUserSeats > 1
      ? [
          {
            name: 'account.items.team',
            route: '/account/team',
          },
        ]
      : []),
  ],
  name: 'account.title',
});
